<template>
    <section id="mission" class="py-5">
        <the-banner-small>Doel</the-banner-small>
        <div class="container my-3">
            <div class="row">
                <div class="col-12">
                    <h3>Onze missie</h3>
                    <p>
                        Het Sint-Martinusfonds is een noodfonds voor minderbedeelden: alleenstaanden, gezinnen, verslaafden, daklozen, vluchtelingen, ...
                        Huidskleur of ras of taal, spelen hier geen enkele rol.
                        Het zijn mensen die, al dan niet tijdelijk, nood hebben aan bijkomende steun.
                    </p>
                    <p>
                        Enerzijds gaat het om een materiële ondersteuning van de dagelijkse behoeften onder de vorm van:
                        <ul>
                            <li>een gratis voedselpakket, dat de leden van het Sint-Martinusfonds elke 2° donderdag van elke maand kunnen ophalen.  Deze pakketten worden bedeeld per gezin, en de grootte van het pakket is afhankelijk van de grootte van het gezin.</li>
                            <li>Gratis kleding voor dames, heren, kinderen voor de 4 seizoenen</li>
                            <li>Gratis klein huishoudgerief en klein meubel (indien voorradig)  </li>
                            <li>Huishoudtextiel : lakens, dekens, gordijnen, …</li>
                            <li>Gratis speelgoed</li>
                            <li>Gratis schoenen voor dames, heren, kinderen (wat voorradig is).</li>
                            <li>boeken</li>
                        </ul>
                    </p>
                    <p>
                        Anderzijds wil het Sint-Martinusfonds, door het voeren van discrete gesprekken en het geven van financieel en sociaal advies, helpen om de levensomstandigheden van behoeftige
                        mensen te verbeteren. Ook is het de bedoeling om mensen door te verwijzen naar instellingen (zoals vb. OCMW) en professionele hulpverleners,
                        die deze mensen dan verder kunnen begeleiden.
                    </p>
                </div>
                <div class="col-12">
                    <h3>Wie kan bij het SMF terecht?</h3>
                    <p>
                        Mensen van allerlei pluimage en van om het even welke huidskleur, ras of taal, van om het even welke ideologische of religieuze of filosofische overtuiging, kunnen bij ons terecht.
                        Zowel voor de kleine kleuter als voor de eeuweling (en ouder), wordt de deur open gedaan.
                        Met rang of stand wordt geen rekening gehouden, omdat ieder mens belangrijk is en deel uitmaakt van de schepping.
                        Het weigeren van enig hulp zou getuigen van weinig respect voor deze mens en dit staat haaks op humane en/of christelijke ethiek.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TheBannerSmall from '../components/layout/TheBannerSmall'

export default {
    components: {
        TheBannerSmall
    }
}
</script>

<style>

</style>
